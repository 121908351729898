.errorimage{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}
.errortext{
   text-align: center;
padding: 10px;
}