body{
    margin: 0px !important;
}

@font-face {
    font-family: "AB Gilroy Bold";
    font-weight: 700;
    src: url(https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-bold-webfont.woff2) format("woff2");
  }
  
  @font-face {
    font-family: "AB Gilroy SemiBold";
    font-weight: 600;
    src: url(https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-semibold-webfont.woff2) format("woff2");
  }

  
  @font-face {
    font-family: "AB Gilroy";
    font-weight: 500;
    src: url(https://unpkg.com/@abds/styles@3.2.2/src/fonts/abgilroy-medium-webfont.woff2) format("woff2");
  }
  