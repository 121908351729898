.topnav-container {
  background-color: rgb(60, 40, 155);
  color: black;
  display: flex;
  align-items: center;
  padding: 2vh 4vh;
  height: 6vh;
  width: auto;
  border-bottom-style: solid;
  border-bottom-color: rgb(60, 40, 155);
}

.topnav-logo {
  height: 3vh !important;
  width: 10.5vw !important;
}
.ant-btn-primary.topnav-guide:hover {
  background-color: rgb(60, 40, 155) !important;
  color: white !important;
  border-color: white !important;
}
.topnav-text {
  color: white;
  font-size: 4.3vh;
  width: fit-content;
  margin-left: -1.5vw !important;
  font-family: 'AB Gilroy';
}


.search {
  margin-left: auto !important;
  margin-right: 1vw !important;
  height: 3.5vh;
  width: 150;
}

.ant-select-dropdown .ant-select-item {
  font-family:'AB Gilroy';
  color: rgba(0, 0, 0, 0.88);
}
.ant-input-affix-wrapper .ant-input-prefix{
  color: rgb(60, 40, 155);
}

.topnav-guide {
  color: rgb(60, 40, 155);
  background-color: white;
  margin-right: 1vw;
  font-weight: 500;
  font-size: 2vh;
  display: flex;
  height: 3.5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1vh;
  padding-right: 1vh;
  align-items: center;
}

.topnav-setting-icon {
  color: white;
  font-size: 2.7vh;
  margin-right: 1vw;
  transition: rotate 1s;
}

.topnav-notification-icon {
  color: white;
  font-size: 2.7vh;
  margin-right: 1vw;
  cursor: pointer;
}
.ant-btn{
  font-family: 'AB Gilroy';
}
.ant-input{
  font-family: 'AB Gilroy';
}

@media (max-width: 768px) {
  .topnav-container {
    padding: 2vh; 
  }

  .topnav-logo {
    height: 1.5vh; 
  }

  .topnav-text {
    font-size: 2.5vh; 
  }

  .search {
    margin-right: 2vh;
  }

  .topnav-guide {
    font-size: 1.8vh; 
    margin-right: 2vh; 
  }

  .topnav-setting-icon,
  .topnav-notification-icon {
    font-size: 2vh; 
    margin-right: 1vh; 
  }
  .topnav-notification-icon-present{
  
    font-size: 2vh; 
    margin-right: 1vh; 
  }

}
.topnav-setting-icon:hover{
  rotate: 180deg;
}

.topnav-notification-icon:hover{
  animation: ring 1s;
  animation-iteration-count: 1;
}

.topnav-notification-icon-present{
  color: white;
  font-size: 2.7vh;
  margin-right: 1vw;
  cursor: pointer;
  animation: ring_notification 2s;
  animation-iteration-count: infinite;
}

@keyframes ring_notification {
  0% { transform: rotate(0); color: white; }
  20% { transform: rotate(30deg); }
  40% { transform: rotate(-28deg); }
  60% { transform: rotate(34deg); color: #E6008C; }
  80% { transform: rotate(-32deg); }
  0% { transform: rotate(0); color: white;}
}

@keyframes ring {
  0% { transform: rotate(0); }
  20% { transform: rotate(30deg); }
  40% { transform: rotate(-28deg); }
  60% { transform: rotate(34deg); }
  80% { transform: rotate(-32deg); }
  0% { transform: rotate(0); }
}

.ant-dropdown  {
  /* padding: 8px 12px !important; */
  /* margin-left: 70vh !important; */
  cursor: text;
}
.notification-item {
  display: flex;
  align-items: center;
}

.notification-text {
  width: 100%;
  font-size: 1.5vh;
  cursor: text;
}

.read-icon {
  margin-left: 10px;
  cursor: pointer;
  color: rgb(60, 40, 155);
}