--root {
  --primary: rgb(60, 40, 155)
}


.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
  width: 10vh;
  text-align: left;
  font-size: 1.5vh !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  position: relative;
  color: white;
  font-weight: 800;
  text-align: center;
  background: rgb(60, 40, 155);
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
  font-size: 1.7vh !important;
  font-family: "AB Gilroy";
}

.ant-table-wrapper .ant-table-tbody .ant-table-row>.ant-table-cell-row-hover {
  background: rgb(226, 219, 239);
  font-weight: 500;
  font-family: "AB Gilroy";
}

/* form */
.ant-form {
  margin-top: -5vh; 
  color: rgb(60, 40, 155);
  font-weight: 500;
  font-family: "AB Gilroy";
}
.ant-input {
  font-family: "AB Gilroy";
}

.ant-btn {
  padding: 6px 15px;
}

.sectionbuttons {
  text-align: left;
  vertical-align: middle !important;
  margin-left: 3vh;
  position: relative; 
}
.sectionbutton {
  font-size: 1.2vh !important;
  font-family: "AB Gilroy";
  color: rgb(60, 40, 155);
  text-align: center;
  border-radius: 1vh;
  font-size: 21px;
  background-color: white;
  border-color: var(--primary);
}
.sectionbutton:hover {
  background-color: rgb(60, 40, 155);
  color: rgb(255, 255, 255);
}

.usecasebuttons {
  text-align: left;
  margin-left: 3vh;
  position: relative;
  vertical-align: middle !important;
}
.usecasebutton {
  font-size: 1.2vh !important;
  font-family: "AB Gilroy";
  color: rgb(60, 40, 155);
  text-align: center;
  border-radius: 1vh;
  font-size: 21px;
  background-color: white;
  /* padding: 10px 20px 10px 20px; */
  
  border-color: var(--primary);
}
.usecasebutton:hover {
  background-color: rgb(60, 40, 155);
  color: rgb(255, 255, 255);
}
.preferencebuttons {
  text-align: left;
  vertical-align: middle !important;
  margin-left: 3vh;
  position: relative;
  
}
.preferencebutton {
  font-size: 1.2vh !important;
  font-family: "AB Gilroy";
  color: rgb(60, 40, 155);
  text-align: center;
  border-radius: 1vh;
  font-size: 21px;
  background-color: white;
  /* padding: 10px 20px 10px 20px; */
  
  border-color: var(--primary);
}
.preferencebutton:hover {
  background-color: rgb(60, 40, 155);
  color: rgb(255, 255, 255);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: white;
  background: rgb(60, 40, 155);
  border-color: white;
}

/* drawer button */

.Two {
  background-color: white;
  color: rgb(60, 40, 155);
  /* margin-top: 30vh !important; */
  margin-left: 10vw !important;
  height: 3.5vh;
  border-color: rgb(60, 40, 155);
  align-items: center;
  font-size: 1.3vh !important;
  font-family: "AB Gilroy";
}

/* .Reset {
  background-color: white;
  color: rgb(60, 40, 155);
  /* margin-left: 8vw;
  height: 4vh; 
  border-color: rgb(60, 40, 155);
  align-items: center;
  font-size: 1.2vh !important;
  font-family: "AB Gilroy";
} */

.Reset {
  color: rgb(60, 40, 150);
  font-size: 1.5vh;
  font-family: "AB Gilroy";
  display: block; 
  text-decoration: underline; 
  cursor: pointer;
}
.Reset:hover{
  color: rgb(60, 40, 150);
  font-size: 1.6vh;
  font-family: "AB Gilroy";
  display: block; 
  cursor: pointer;
}
.ant-drawer .ant-drawer-header {
  background-color: rgb(60, 40, 155);
  height: 6vh;
}

.ant-drawer .ant-drawer-header-title {
  text-align: center;
  font-weight: bold;
  font-family: "AB Gilroy";
}

.ant-drawer .ant-drawer-title {
  color: white;
  font-size: 20px;
  font-family: "AB Gilroy";
}

.ant-drawer .ant-drawer-body {
  padding-top: 11vh;
}
/* .tabs-container {
  margin-left: 2.4vh;
  width:97.4%
} */

.ant-layout-content {
  min-height: 0vh !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgb(60, 40, 155);
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: rgb(60, 40, 155);
}

.ant-table-wrapper {
  max-width: 100%;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(60, 40, 155);
}

.ant-table-wrapper .ant-table-column-sorters:hover .ant-table-column-sorter {
  background: transparent;
  color: white;
}

.ant-table-wrapper .ant-table-filter-trigger:hover {
  color: white;
  background: transparent;
}

.ant-table-wrapper .ant-table-column-sorter {
  margin-inline-start: 4px;
  color: white;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: white;
}


.swiper-button-prev, .swiper-button-next {
  color:rgb(60, 40, 155) !important;
}

.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  padding-left: 3vh !important;
}
.ant-tabs-centered >.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='.ant-tabs-nav-wrap-ping']){
  justify-content: normal;
}

.ant-card .ant-card-head {
  min-height: 4.7vh;
}
.ant-table-thead{
  height: 4.9vh;
}
@media (max-width: 768px) {
  .tab-button {
    padding: 12px 15px;
  }
  .ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='.ant-tabs-nav-wrap-ping']) {
    margin-left: 2vh;
 
  }
}

.ant-tabs-nav {
  /* background: rgb(60,40,155);
  background: linear-gradient(90deg, rgba(60,40,155,1) 30%, rgba(255,255,255,1) 100%); */
}


.ant-spin-container{
  margin-top: 15vh !important;
  padding: 0px !important;
}

/* .settingscard{
  left: 14.4vw;
  position: absolute;
  top: 12vh !important;
  right:1vw;
  width: 84.3vw !important;
  height:16vh !important;

  font-size: 1.2vh !important;
 
  color: rgb(60,40,155);;
  font-family: 'AB Gilroy';
} */

.ant-spin-container {
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
}

#root > div > div.ant-layout.css-dev-only-do-not-override-p7e5j5 > div > div > div:nth-child(1) > div > div.ant-card-body
{
  background-color: white !important;
}

.settingscard p:not(:last-child){
  border-bottom: 0.1vh solid #dadada;  
}

/* .usecasecard{
  height: 21vh !important;
} */

/* .impactcard{
  height: 18vh !important;
} */

/* .updatescard{
  height: 18vh !important;
} */
.topmaintable{
   
    max-width: 97%;
    display: grid;
    grid-template: "left right" / 100% 1fr;
    margin-left: 2.8vh;
    border-radius: 1vh;
    height: 25vh;
    font-size: 1vh;
    margin-bottom: -10vh;
    font-family: "AB Gilroy";
  }
  

.ant-table-cell{
  font-size: 1.2vh !important;
  font-family: "AB Gilroy";
}

.ant-drawer-body{
  font-size: 1.2vh !important;
  font-family: 'AB Gilroy';
}

.ant-table-wrapper .ant-table{
  margin-left: 2.8vh;
  margin-right: 2.5vh;
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab {
  font-size: 1.5vh;
  font-family: "AB Gilroy";
  background-color: white;
  color:rgb(60, 40, 155) !important;
  /* border: 1px solid white !important; */
  /* border-radius: 1vh; */
  padding: 1vw;
  align-items: center !important;
  width:7vw !important;
  text-align: center !important;
  cursor: pointer;
  transition: background-color 0.1s;
  height: 3vh !important;
  transition: all 0.3s !important;
}
.ant-tabs-tab{
  margin-top: 1.5vh !important;
  /* margin-left: 20vw !important; */
/* align-items: center !important; */
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  background-color:rgb(60,40,155) !important;
  color: white !important;
  border: 1px solid white !important;
}

.ant-tabs-tab-active {
  align-items: center !important;
  background-color: rgb(60, 40, 155) !important;
  margin-bottom: 0px !important;
  color: white !important; 
  border: 1px solid white !important;
}

.ant-tabs-tab{
  border: 1px solid rgb(60,40,155) !important;
  
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: white !important;
}
.ant-table-filter-dropdown {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.ant-table-filter-dropdown {
  width:25vw; 
}
.checked-row {
  background-color: rgb(216, 219, 239);
}