.landinglanding{
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: absolute;
    top:0;
    z-index: 2;
    color:black;
}
.InitialSidedivlanding
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    background-image: url('../../../public/ndccockpit.png');
    background-size: cover;
    background-position: center;
    z-index: 3;
    transition: width 0s;
}

.InitialSidedivoverlaylanding{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(75, 32, 160, 0.70) 40%, rgba(230, 0, 140, 0.70) 70%);
    animation-name: ndccockpit;
    z-index: 3;
    transition: width 0s;
}


.sidedivlanding
{
    position: absolute;
    top: 0;
    left: 0;
    width: 40vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    background-image: url('../../../public/ndccockpit.png');
    background-size: cover;
    background-position: center;
    z-index: 3;
    transition: width 0s;
}

.sidedivoverlaylanding{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0px;
    padding: 0px;
    width: 40vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(75, 32, 160, 0.70) 40%, rgba(230, 0, 140, 0.70) 70%);
    animation-name: ndccockpit;
    z-index: 3;
    transition: width 0s;
}

.welcomelanding{
    position: absolute;
    top: 40vh;
    width: 100%;
    color: #FFF;
    text-align: center;
    /* font-family: Gilroy-SemiBold; */
    font-family: 'AB Gilroy';
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

.ndccockpitlanding{
    position: absolute;
    top: 47vh;
    width: 100%;
    color: #FFF;
    text-align: center;
    /* font-family: Gilroy-SemiBold; */
    font-family: 'AB Gilroy';
    font-size: 8vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;
}

.rightdivlanding{
    position: absolute;
    top: 20vh;
    right: 5vw;
    width: 50vw;
}



.usecaselanding{
    height: 30vh;
    background-image: linear-gradient(#461E96, #0073BE);
    border-radius: 0.9em;
    margin-bottom: 2vh;
}
 
.simulatelanding{
    height: 30vh;
    background-image: linear-gradient(#461E96, #691794, #821392, #E6008C);
    border-radius: 0.9em;
}
 
.usecaseimagelanding{
    transition: transform 4s;
    position: relative;
    top: 0.5vh;
    left: 0.5vh;
    border-top-left-radius: 0.6em;
    border-bottom-left-radius: 0.6em;
    height: 96.56%;
    width: 40%;
    background-image: url('../../../public/usecaseimage.png');
    background-size: cover;
    background-position: center;
    transition: all 2s !important;
}
 
.usecasetextlanding{
    position: relative;
    top: -28.5vh;
    padding: 4vw;
    right: -39.95%;
    border-top-right-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
    height: 97%;
    width: 59.5%;
    background-color: rgb(227, 232, 234);
    color: #000;
    text-align: justify;
    font-size: 1rem;
    /* font-style: ; */
    font-weight: 400;
    font-size: 2vh;
    /* font-family: Gilroy-Regular; */
    cursor: default;
    font-family: 'AB Gilroy';
}
 
.simulateimagelanding{
    transition: transform .2s;
    position: relative;
    top: 0.5vh;
    left: 0.5vh;
    border-top-left-radius: 0.6em;
    border-bottom-left-radius: 0.6em;
    height: 96.56%;
    width: 40%;
    background-image: url('../../../public/simulateimage.png');
    background-position: center;
    background-size: cover;
    transition: all 2s !important;
}
 
.simulatetextlanding{
    position: relative;
    padding: 4vw;
    top: -28.5vh;
    right: -39.95%;
    border-top-right-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
    height: 97%;
    width: 59.5%;
    background-color: rgb(227, 232, 234);
    color: #000;
    text-align: justify;
    /* font-size: 1rem; */
    /* font-style: normal; */
    font-weight: 400;
    font-size: 2vh;
    /* font-family: Gilroy-Regular; */
    cursor: default;
    font-family: 'AB Gilroy';
   
}

.viewusecaselanding{
    font-size: 1.8vh;
    position: absolute;
    top: 22vh;
    height:5vh;
    right: 4vw;
    width: 10vw;
    background: linear-gradient(93deg, #461E96, #0073BE);
    display: none;
    animation: fadeIn;
    animation-duration: 2s;
    animation-iteration-count: 1;
    text-align: center;
    justify-content: center;
    font-family: 'AB Gilroy';
}

.viewusecasevisitedlanding{
    font-size: 1.8vh;
    position: absolute;
    top: 22vh;
    height:5vh;
    right: 4vw;
    width: 10vw;
    background: linear-gradient(93deg, #461E96, #0073BE);
    display: none;
    animation: fadeIn;
    animation-duration: 2s;
    animation-iteration-count: 1;
    text-align: center;
    justify-content: center;
    font-family: 'AB Gilroy';
}

.viewusecaselanding:hover{
    background: linear-gradient(93deg,  #0073BE,#461E96) !important;
}

.gotosimulatelanding{
    font-size: 1.8vh;
    position: absolute;
    top: 22vh;
    height:5vh;
    right: 4vw;
    width: 10vw;
    background: linear-gradient(94deg, #461E96, #691794, #821392, #E6008C);
    display: none;
    animation: fadeIn;
    animation-duration: 2s;
    animation-iteration-count: 1;
    text-align: center;
    justify-content: center;
    font-family: 'AB Gilroy';
}

.gotosimulatevisitedlanding{
    font-size: 1.8vh;
    position: absolute;
    top: 22vh;
    height:5vh;
    right: 4vw;
    width: 10vw;
    background: linear-gradient(94deg, #461E96, #691794, #821392, #E6008C);
    display: none;
    animation: fadeIn;
    animation-duration: 2s;
    animation-iteration-count: 1;
    text-align: center;
    justify-content: center;
    font-family: 'AB Gilroy';
}


@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 } 


.gotosimulatelanding:hover{
    background: linear-gradient(94deg,#E6008C ,#821392 ,#691794 , #461E96 ) !important
}

.messagelanding{
    z-index: 3 !important;
}