.ant-layout .ant-layout-sider {
    position: relative;
    background: #3c289b;
    padding-top: 3vh;
    overflow: hidden;
    min-width: 13vw !important;
    font-family: 'AB Gilroy' !important;
}

.ant-layout-sider-children,
.side-nav-1 {
    
    background-color: transparent;
    font-size: 1.5vh !important;
    z-index: 3 !important;
    font-family: 'AB Gilroy' !important;
}

.side-nav-1 li{
    height: fit-content !important;
    transition: all 1s !important;
}


.sidenavtree{
    margin-left: 0.5vw;
    margin-bottom: 0.5vw;
    border-left: 2px dashed #3c289b;
    /* background-color: red; */
}

.sidenavtree ul{
    margin-left: 0.5vw;
    /* border-bottom: 2px solid #3c289b; */
    /* border: 2px dotted #3c289b; */
    color: #3c289b;
    margin: 0.5vh;
    border-radius: 0.5vw;
    font-size: 1.3vh !important;
}


.ant-menu-title-content {
    color: white !important;
    font-family: 'AB Gilroy' !important;
}

.ant-menu-title-content:hover {
    color: white ;
    font-weight: 500;
    font-family: 'AB Gilroy';
    font-family: 'AB Gilroy' !important;
}

.ant-menu-light .ant-menu-item-selected a{
    color: #432392 !important;
    /* font-weight: bold; */
    font-size: 1.7vh;
    font-family: 'AB Gilroy' !important;
}

.ant-menu-light .ant-menu-item-selected a:hover{
    color: #432392 !important;
    font-weight: normal;
    font-size: 1.7vh;
    font-family: 'AB Gilroy' !important;
}

.ant-menu-light .ant-menu-item-selected{
    background-color: white !important;
    font-size: 1.7vh;
    font-family: 'AB Gilroy' !important;
}
.ant-layout-content {
    padding: 0px;
}

#root > div > div.ant-layout.css-dev-only-do-not-override-p7e5j5 > div > aside > div > ul > li{
    margin-bottom: 2vh !important;
}