.ant-carousel .slick-dots li button,
.ant-card,
.tabs,
.ant-carousel,
.detailed-card-wrapper,
.default-card-wrapper {
    font-family: 'AB Gilroy';
}
body {
    overflow-y: hidden; 
    overflow-x: hidden; 
    }


.ant-list.ant-list-split.listitems.css-dev-only-do-not-override-p7e5j5 {

    margin-top: 1vh;
}

.ant-carousel .slick-dots {
    display: none !important;
}

.ant-card .ant-card-body {
    padding-top: 0px;
    justify-content: center;
}

.ant-card {
    font-size: 1.5vh;
    border-radius: 0px;
}

.detailed-card-wrapper {
    margin-top: 2.6vh;
}

.default-card-wrapper {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 3.1vh;
}

.ant-card .ant-card-head {
    background: rgb(60, 40, 155);
    color: white;
    height: 3vh;
    align-items: center;


}

.card1-label {
    margin: 3vh 0vh 1vh 0vh;
    font-size: 4vh;
    font-family: 'AB Gilroy';
}

.card1-desc {
    margin: 3vh 0vh 1vh 0vh;
    font-size: 2.5vh;
    font-family: 'AB Gilroy';
}

.tabs {
    display: flex;
    justify-content: center;
}

.ant-tabs-top>.ant-tabs-nav {
    border-bottom: none;
}


.ant-carousel .slick-slide {
    text-align: left;
}

.card1 {
    
    color: #F4F4F4;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: normal;
    margin-left: 1vh;
    background-image: url('../../../public/background.jfif');
    background-size: cover;
    width: 98%;
    height: 30%;
    border-radius: 1vh;
    margin-top: 0.3vh;
}

.updates-box {
    margin-bottom: 2.9vh;
    margin-top: -15vh;
    font-family: 'AB Gilroy';
    font-size: 1.2vh !important;
    width: 97%;
    border-radius: 1vh;
    border-color: #432392;
    height: 10%;
    
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-list-split .ant-list-item {
    border-block-end: 0.1vh solid black;
}

.listitems,
.listitems1 {
    overflow-y: auto;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.firstupdate {
    flex: 1;
    font-size: 2vh;
    line-height: 2.3vh;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .firstupdate-hovered {
    max-height: none;
  }
  
  

.firstupdate:first-child {
    margin-right: 1vw;
}

.card1 h3 {
    font-size: 4.5vh;
    font-weight: 400;

}

/* .card3 {
    border-color: rgb(204, 195, 195);
    border-radius: 2vh;
} */

.ant-card-body {
    height: 6vh;
}

.flex-container {
    font-size: 2vh;
    margin-top: 1.3vh !important;
    background-color: rgba(162, 142, 202, 0.15);
    border-radius: 1vh;

}

.slick-list {
    color: black;
    margin-top: 1cm;
    cursor: pointer;
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.card2 .ant-card-body div div div{
    margin-top: 0px !important;
    padding: 0px !important;
}

.card2 .ant-list{
    
    margin-top: 1.9vh !important;
    margin-right: -0.5vw !important;
    margin-left: -0.5vw !important;
    text-align: justify;
}


.listitems .ant-list-item {
    padding-top: 2vh !important;
    font-size: 1.5vh;
    font-family:'AB Gilroy' ;
    padding-left: 0.5vw;
    text-align: left;
    padding-bottom: 2vh !important;
}



#root>div>div.ant-layout.css-dev-only-do-not-override-p7e5j5>div>div>main>div.container.my-3>div>div.ant-row.css-dev-only-do-not-override-p7e5j5>div:nth-child(1)>div>div>p {
    font-family: 'AB Gilroy' !important;
    font-size: 2vh;
    line-height: 3vh;
    margin-top: -2vh !important;
}

.card-btn{
    position: relative;
    left: 30.7vw;
    top: -2.5vh;
    color: white !important;
}

.view{
    position: fixed; 
    left: 2.2vw;
    bottom: 3vh;
    background-color: white;
    color: black;
    font-family: 'AB Gilroy';
    width: 4vw;
    font-size: 1.8vh;
    padding: 0;
}

.view:hover{
    background-color: white !important;
    font-family: 'AB Gilroy';
    color: black !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.Dashboardtitle{
    margin-left: 2vh;
    font-size: 3.5vh;
    font-weight: 400;
    font-family: 'AB Gilroy';
    color: rgb(60, 40, 155);
}

.swiper-slide-next{
    border-radius: 1vh !important;
    opacity: 0.5;
    background:rgb(60,40,155) !important;
}
 
.swiper-slide-prev{
    border-radius: 1vh !important;
    opacity: 0.5;
    background:rgb(60,40,155) !important;
}
 
.swiper-slide{
    transition-duration: 0.5s !important;
}
 
.swiper-slide-shadow-left{
    border-radius: 1vh !important;
}
 
.swiper-slide-shadow-right{
    border-radius: 1vh !important;
}

.reportClass{
    height: 80vh !important;
    border-color: #432392 !important;
}

.refreshtoken{
    position: absolute;
    top: 7vh;
    width: 1.8vw !important;
    right: 2vw;
    color:rgb(60,40,155);
    font-weight: bolder;
    border: 1px solid rgb(60,40,155);
}

.goback{
    position: absolute;
    top: 11vh;
    width: 1.8vw !important;
    right: 2vw;
    color:rgb(60,40,155);
    font-weight: bolder;
    border: 1px solid rgb(60,40,155);
}

.viewinpbi{
    position: absolute;
    top: 11vh;
    right: 4.2vw;
    width: 1.8vw !important;
    color:rgb(60,40,155);
    font-weight: bolder;
    border: 1px solid rgb(60,40,155);
}

.downloadimage{
    position: absolute;
    top: 11vh;
    right: 6.4vw;
    width: 1.8vw !important;
    color:rgb(60,40,155);
    font-weight: bolder;
    border: 1px solid rgb(60,40,155);
}

.downloadloader{
    position: absolute !important;
    top: 11.5vh;
    left: 90vw;
    font-size: 2vh !important;
    padding: 0px !important;
    margin: 0px !important;
    color:rgb(60,40,155) !important;
}

.refreshtoken:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: rgb(60,40,155) !important;
    color:white !important;
}

.viewinpbi:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: rgb(60,40,155) !important;
    color:white !important;
}

.downloadimage:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: rgb(60,40,155) !important;
    color:white !important;
}

.goback:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: rgb(60,40,155) !important;
    color:white !important;
}

.loader{
    position:absolute !important;
    top:45vh !important;
}

.displayArea{
    width: 100% !important;
}

.card2{
    /* margin: 0px !important; */
    overflow-y: auto !important;
}

#dashboard{
    border: 3px solid grey !important;
    background-color: white !important;
}
.ant-input{
    font-family: 'AB Gilroy';
}

.card1-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    max-width: 100%;
  }

  .card1-desc:hover {
    white-space: normal;
  }